import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.svg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img
        style={{
          width: 88,
          height: 88
        }}
        src={logo}
      />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Jonathon Richardson</h1>
        <p>
          By day, I am a software engineer/technical lead with extensive experience in full-stack development, devops, database design, and
          project management.
        </p>
        <p>
          By night, I am a maker and content creator with passions for S.T.E.A.M. education, craftsmanship, videography,
          and photography.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('mcv')
            }}
          >
            Mad City Viking
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
