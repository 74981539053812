import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            My name is Jonathon and I'm a Software Engineer/Technical Lead from Madison, Wisconsin.  I enjoy
            a diverse array of hobbies including lockpicking, woodworking, guitar playing,
            electronics, kombucha brewing, knitting, and drone piloting, to name a few.
          </p>
          <p>
            I absolutely adore the city of Madison. I love to hike, bike, and canoe around
            the city and its lakes.  As is any good Viking, I'm a lover of craft beers and meads
            (which are never in short supply around here), and find they go billiantly with a
            round of darts or a game of pool in the friendly neighborhood tavern.  Madison has a rich 
            music scene, which I try to soak up as often as I can.
          </p>
          <p>
            In the Summer, you'll catch me playing volleyball, cornhole, going golfing, and grilling
            whenever I get the chance.  Come Fall and into Winter, I don't miss a game that the UW Madison 
            Badger football team plays.  And before Spring comes again, I try to get in a little
            snowshoeing and ice skating.
          </p>
          <p>
            In woodworking, software, and life, I'm a big believer in using the right
            tool for the job.  This includes not being afraid to build my own tools,
            jigs, and templates, when the need arises.
          </p>
          <p>
            I'm a fast learner and able to figure out how to work with new things,
            even with little documentation or guidance, which makes me great at blazing
            new paths and helping pave the way for others to follow.  Professionally,
            this means I'm good at jumping into unfamiliar legacy systems, 
            experimenting with and implementing new technologies, and integrating old
            and new sytems together.  Personally, it means I enjoy jumping in and learning
            new skills whenever I can, which means I work on an extremely wide array of
            personal projects.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3>What I'm Doing Now</h3>
          <p>
            As a technical architect, my goal is to ensure the smooth delivery of a reliable
            product, which means establishing a strong SDLC built on an automated CI/CD platform
            that allows for rapid and frequent deliveries of code to testing environments (often 
            multiple times per day).  We use testing and automated build processes to be confident
            that when we review and merge code, we maintain a stream of stable deliveries.  Deployments
            are automated so that non-technical stakeholders such as testers and product owners can update their
            environments with the latest updates when they want, without developer interaction.
          </p>
          <p>
            I design components, their composition/orchestration, data structures, and interfaces, while guiding
            and mentoring developers to implement end-user stories.  I automate developer tasks such as
            API client code generation and database model generation to ensure such tasks are as push-button as
            possible.
          </p>
          <p>
            I often consult on topics such as UI design, security, and DevOps to optimize for delivery time,
            simplicity, cost, and testability.  I have a great breadth of experience with various technologies
            and platforms that has left me with a good intuition for patterns that work and patterns that don't,
            as well as being aware of tools to solve many of the problems that most projects come up against.
          </p>

          <h3>What I Like to Do</h3>

          <p>
            I particularly enjoy putting SaaS products (both new and legacy) onto the rails of a good SLDC train,
            modernizing or architecting them to be able to meet the needs of their users.  I love being able to
            build and design Line-Of-Business products and software that can accelerate the work that
            organizations do by automating the processes that machines do best and allowing the humans to focus
            on the things they do best.
          </p>

          <p>
            These users include software developers.  I also enjoy building tooling and scripts to make developers
            lives easier, using things like code generation, plugins for Docker, VSCode, etc, and tools such as 
            Vault to keep developers productive and not worrying about boilerplate or connective code between components.
          </p>

          <h3>My Tenets of Software Development</h3>

          <p>
            A properly designed and implemented SDLC takes most of the luck, instability, and tedium out of the
            software development process.  It stays out of the way of the team (developers, QA'ers, etc), and
            allows each part of the organization to focus on what they do best.
          </p>
          <p>
            Minimize Bus Factor
            <ul>
              <li>
                A well designed and maintained project should documented and architected in such a way that the absence
                of any individual will not derail the entire project.
              </li>
              <li>
                Architects should be focusing on designing and building frameworks and systems that require little maintanance
                on their part.  In other words, we should always be seeking to "put ourselves out of a job", so that we can
                focus on building bigger, better, and faster: not merely keeping the existing stuff running.
              </li>
            </ul>
          </p>
          <p>
            All Builds and Deploys Should Be Automated
            <ul>
              <li>
                This pays for itself in time savings over and over again.
              </li>
              <li>
                When all infrastructure and code is version controlled and deployments are automated, you have high confidence that
                anything that works in lower environments (such as dev and test environments) will work in production.
              </li>
            </ul>
          </p>
          <p>
            Documentation Is King
            <ul>
              <li>
                Documentation is a living, breathing, and critical part of the app.  This includes both informal inline code comments
                and formal structured documents.  Don't be afraid to keep an informal FAQ with questions that get asked about the project, since
                they'll probably be asked more than once.
              </li>
              <li>
                Version and store documentation that could change when the code changes with the code.  Documentation that changes
                independently of code changes (such as environment names, etc) should not be stored with the code.
              </li>
              <li>
                You + 2 weeks ≠ You.  Documentation will help even when it's only one person working on the project.  Trust me, you'll
                thank yourself.
              </li>
              <li>
                A project should contain all the instructions needed such that a person getting access to the repository knows exactly
                what to do to build and run the project.
              </li>
              <li>
                If a feature isn't documented, it doesn't exist.
              </li>
            </ul>
          </p>
          
          <p>
            Architect for Security
            <ul>
              <li>
                Whenever possible, architect around security vulnerabilities.  Avoid the iceberg field entirely, if possible,
                rather than trying to rely on patching holes in a sinking ship.
              </li>
              <li>
                Make the developer have to go out of their way to introduce vulnerabilities whenever possible.  For instance, if you use React
                to avoid most XSS vulnerabilities (unless someone uses <strong>__dangerouslySetHTML()</strong>), API tokens instead
                of cookies to avoid CSRF vulnerabilities, and libraries like NPoco (which makes parameterized SQL queries extremely easy to use)
                to avoid SQL injection, you can prevent many of the most common sources of security vulnerabilities dead in their tracks.
              </li>
              <li>
                Using containers with strong secrets management (such as Vault) and least privaleges based network security, even on developer 
                machines, means that when breaches do occur (such
                as through poisoned NPM or Nuget packages), they stay isolated and have either limited or no access to anything of value (data, passwords, etc).
                Using rotating credentials mitigates the viability of leaked secrets to a small time window, and configuring your network to only allow
                communication along expected, whitelisted channels (most running containers don't need complete internet access) means that compromised
                components and code usually can't communicate to external servers.
              </li>
            </ul>
          </p>

          <p>
            Telemetry and Logging Are First Class Citizens
            <ul>
              <li>
                Proper telemetry and logging is not terribly difficult to accomplish, and aggregating structured logs in systems like Elasticsearch/Kibana
                makes the identification and resolution of issues in environments in the cloud astronomically easier.
              </li>
              <li>
                Administrators should be able to review and alert off of logs easily.  Developers should update logging based on feedback from those reviewing
                the logs to ensure that noise is minimal and information is easy to extract. 
              </li>
            </ul>
          </p>
          {close}
        </article>

        <article
          id="mcv"
          className={`${this.props.article === 'mcv' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Mad City Viking</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Mad City Viking is a brand, initiative, and platform to share the things I've learned
            and give back to the community and knowledge base of the internet.&nbsp;&nbsp;Although still in the early stages
            of development, it is a platform for me to produce and procure/curate articles and videos on
            the following topics:
            <ul>
              <li><strong>MCV-U</strong>: Mathematics, Science, and the History Thereof</li>
              <li><strong>MCV Forge</strong>: Making and Craftsmanship
                <ul>
                  <li>Woodworking and Construction</li>
                  <li>Metal Working</li>
                  <li>Computer Aided Manufacturing (CNC, 3D Printing, Laser Cutting, etc)</li>
                  <li>Book Binding</li>
                </ul>
              </li>
              <li><strong>eMCV</strong>: Software and Electrical Engineering</li>
              <li><strong>MCV Musings</strong>: Miscellaneous Interesting Bits of Information</li>
            </ul>
          </p>
          <p>
            Many of these intertwine and will intersect each other.  When I have the content in a more mature state, I
            will post links here.
          </p>
          <p>
            This is a cross platform initiative, combining Twitter, Instagram, blogging, and YouTube videos to provide
            and entertaining and accurate source of information on the aforementioned topics.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/moqykvdb" method="post">
            <fieldset id="fs-frm-inputs">
              <label for="full-name">Full Name</label>
              <input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
              <label for="email-address">Email Address</label>
              <input type="email" name="_replyto" id="email-address" placeholder="email@domain.tld" required="" />
              <label for="message">Message</label>
              <textarea rows="5" name="message" id="message" placeholder="Aenean lacinia bibendum nulla sed consectetur. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla nullam quis risus." required=""></textarea>
              <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission" />
            </fieldset>
            <input type="submit" value="Submit" />
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/jrichardson1843"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://stackexchange.com/users/5456719/jonathon-richardson?tab=accounts" className="icon fa-stack-overflow">
                <span className="label">Stack Overflow</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/mad_city_viking/" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/jonathon-richardson-728973b6/" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/JonathonRichardson"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
