import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Jonathon Richardson · This website is based on the <a href="https://www.gatsbyjs.com/starters/codebushi/gatsby-starter-dimension/">Dimension</a> starter for <a href="https://www.gatsbyjs.org/">Gatsby.js</a> by HTML5UP · All Images By Jonathon Richardson</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
